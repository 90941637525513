import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import ContactSubscribeProSection from '../components/sections/ContactSubscribeProSection';

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Us" />

    <Header graphic="3">Contact Us</Header>

    <ContactSubscribeProSection />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default ContactPage;
